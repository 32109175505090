<template>
  <div>
    <!-- HEADER -->
    <!-- <header-component noAction v-if="!cliente && !expediente"/> -->

    <v-row class="align-center" v-if="!cliente && !expediente">
      <v-col cols="6">
        <h1>Auditorías</h1>
      </v-col>

      <v-col cols="6" style="text-align: right">
        <v-btn
          v-if="ui.showOffline"
          depressed
          outlined
          @click="dropCache"
          color="purple"
          class="mr-1">
          <v-icon small class="pr-2">mdi-water-remove</v-icon>
          Vaciar
        </v-btn>

        <!-- <v-btn depressed outlined @click="ui.showOffline = !ui.showOffline; refreshRows(true)"
          :color="(ui.showOffline) ? 'success' : ''">
          <v-icon small class="pr-2">mdi-lightning-bolt-circle</v-icon>
          <template v-if="ui.showOffline">
            Estoy preparado
          </template>

<template v-else>
            Prepararse para ir offline
          </template>
</v-btn> -->
        <!-- <v-btn depressed outlined @click="goToCreate" :color="'success'">
          <template> Crear Auditoría </template>
        </v-btn> -->
      </v-col>

      <v-col cols="12" v-if="isOffline">
        <v-alert dark icon="mdi-wifi-strength-alert-outline">
          Durante el modo sin conexión únicamente se muestran las auditorías que hayan sido
          precargadas en este dispositivo.
        </v-alert>

        <v-alert dark icon="mdi-alert-box-outline">
          Es importante que no refresque la aplicación mientras se encuentra sin conexión o podría
          quedarse bloqueado.<br />
          También es importante que el dispositivo no entre en modo reposo mientras se encuentra sin
          conexión.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-show="ui.showOffline" class="my-5">
      <v-col>
        <offlineSelector
          ref="offline"
          nope-component="auditoria"
          :enabled="ui.showOffline"
          :onInit="['auditorias/list']"
          :onInitBody="[{}]"
          :cols="[
            { text: 'Estado', value: 'estado' },
            { text: 'Desde', value: 'desde_f' },
            { text: 'hasta', value: 'hasta_f' },
            { text: 'Alcance', value: 'nombre_alcance' },
            { text: 'Cliente', value: 'nombreApellidos' },
            { text: 'Emplazamiento', value: 'emplazamiento' },
          ]"
          :items="allowedToOffline"
          :howToLoad="[
            {
              action: 'clearCache',
              key: 'auditorias/uuid',
            },
            {
              action: 'lockAuditoria',
            },
            {
              component: 'auditoria',
              uuid: 'uuid',
            },
            {
              request: 'checklists/list',
            },
            {
              component: 'checklist',
              uuid: 'uuid',
              loop: true,
            },
            {
              request: 'tomas_muestra/list',
              uuid: 'itemUUID',
              //itemUUIDauditoria-uuid
            },
            {
              component: 'muestras',
              uuid: 'uuid',
              loop: true,
            },
            {
              action: 'downloadClientFiles',
              uuid: 'cliente.uuid',
            },
          ]" />
      </v-col>
    </v-row>

    <!-- LISTADO -->
    <v-row v-show="!ui.showOffline">
      <v-col cols="12">
        <ag-datatable
          :key="ui.refresh"
          :headers="headers"
          :items="items"
          emitClickRow
          urlData="auditorias"
          @click-row="clickRow"
          @gridApi="gridApi = $event">
        </ag-datatable>

        <!-- <v-data-table
          :search="search"
          :loading="ui.loading"
          loading-text="Cargando ..."
          :sort-by-asc="['created_at']"
          :sort-desc="true"
          no-data-text="Listado vacío"
          :headers="headers"
          :items="items"
          :show-select="true"
          :expanded.sync="expanded"
          show-expand>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>
                      Direccion Instalacion :
                      {{
                        item.emplazamientos.length > 0
                          ? item.emplazamientos[0].direccion.direccion
                          : ''
                      }}
                    </td>
                    <td>{{ item.responsable.nombreCompleto }}</td>
                  </tr>
                  <tr>
                    <td>Fecha de Cierre: {{ item.end }}</td>
                    <td>N de Acta:</td>
                  </tr>
                  <tr>
                    <td>Fecha Revision:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Propuesta:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Revisor:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Comentario Revisión:</td>
                    <td></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>

          <template v-slot:item.estado="{ item }">
            <v-chip :color="chipColor(item.estado)" small dense dark>{{ item.estado }}</v-chip>
          </template>

          <template v-slot:item.subalcance="{ item }">
            <v-chip class="ml-1 cursor-pointer" :color="item.subalcance.color" outlined small>
              {{ item.subalcance.nombre }}
            </v-chip>
          </template>

          <template v-slot:item.expediente="{ item }">
            <span style="text-wrap: nowrap">{{ item.expediente.codigo }}</span>
          </template>

          <template v-slot:item.cliente="{ item }">
            <span style="text-wrap: nowrap">{{ item.cliente.nombreCompleto }}</span>
          </template>

          <template v-slot:item.emplazamientos="{ item }">
            <span
              :style="
                'text-wrap: nowrap' +
                (item.emplazamientos && item.emplazamientos.length > 0
                  ? ''
                  : '; font-style: italic; font-weight: bold;')
              ">
              {{
                item.emplazamientos.length > 0
                  ? item.emplazamientos.map((e) => e.direccion.razonSocial).join(', ')
                  : 'Sin emplazamientos'
              }}</span
            >
          </template>

          <template v-slot:item.auditores="{ item }">
            <span style="text-wrap: nowrap">{{
              item.auditores.map((a) => a.nombreCompleto).join(', ')
            }}</span>
          </template>

          <template v-slot:item.tipoVisita="{ item }">

            <span style="text-wrap: nowrap">{{ item.tipoVisita ? item.tipoVisita.map((v) => v).join(', ') : '' }}</span>

          </template>

          <template v-slot:item.n_pacs="{ item }">
            <span style="text-wrap: nowrap">{{ item.pacs.length }}</span>
          </template>

          <template v-slot:item.n_muestras="{ item }">
            <span style="text-wrap: nowrap">{{ item.tomaMuestras.length }}</span>
          </template>

          <template v-slot:item.fechas="{ item }">
            <span v-if="item.start === item.end">{{ item.start }}</span>
            <span v-else> Del {{ item.start }} al {{ item.end }}</span>
          </template>

          <template v-slot:item.acciones="{ item }">
            <v-btn icon small @click="clickRow(item)">
              <v-icon color="deep-purple darken-2">mdi-eye</v-icon>
            </v-btn>

            <v-btn v-if="item.estado === 'PREVISTA'" icon small @click="clickRow(item)">
              <v-icon color="info darken-2">mdi-check</v-icon>
            </v-btn>

            <v-btn v-if="item.estado === 'ABIERTA'" icon small @click="clickRow(item)">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn icon small @click="deleteRow(item)">
              <v-icon color="red darken-2">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table> -->
      </v-col>
    </v-row>
    <confirmation
      v-model="confirmDelete"
      :text="'¿Eliminar la auditoria seleccionada?'"
      colorButton="error"
      @confirm="deleteItem()" />
  </div>
</template>

<script>
import moment from 'moment'
import Estados from '~/models/estados'
import AgDatatable from '~/components/AgDataTableNew'
import HeaderComponent from '~/components/HeaderComponent'
import offlineSelector from '~/components/offline/selectorTable'
import Confirmation from '~/components/Confirmation.vue'

export default {
  props: {
    iteracion: {
      type: Object,
      default: () => ({}),
    },
    cliente: { type: String, default: null },
    expediente: { type: String, default: null },
  },
  components: {
    AgDatatable,
    HeaderComponent,
    offlineSelector,
    Confirmation,
  },

  data: () => ({
    expandItem: null,
    expanded: [],
    headersPinned: true,
    gridApi: null,
    search: '',
    items: [],
    db: { items: [] },
    tipos_auditorias: {},
    selected: [],
    auth: {},
    deleteItemData: null,
    confirmDelete: false,
    ui: {
      loading: false,
      retry: 0,
      refresh: 0,
      showOffline: false,
    },
  }),

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },

    isOffline: function (weAreOffline) {
      this.filterOffline(weAreOffline)
    },
  },

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    headers() {
      let headers = [
        {
          headerName: 'Estado',
          field: 'estado',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellRendererFramework: 'StatusRendererAuditoria',
          valueGetter: (params) => {
            const item = params.data
            if (!item) return 'Sin estado'
            return item.estado
          },
        },
        {
          headerName: 'Marca',
          field: 'subalcance.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellRendererFramework: 'SubalcanceRendererColor',
          valueGetter: (params) => {
            const item = params.data
            if (!item) return []
            return item.subalcance
          },
        },
        {
          headerName: 'Expediente',
          field: 'expediente.codigo',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item) return 'Sin expediente'
            return item.expediente.codigo
          },
        },
        {
          headerName: 'Cliente',
          field: 'cliente.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item) return 'Sin cliente'
            return item.cliente.nombreCompleto
          },
        },
        {
          headerName: 'Emplazamientos',
          field: 'emplazamientos.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item || !item.emplazamientos || item.emplazamientos.length === 0)
              return 'Sin emplazamientos'
            return item.emplazamientos.map((e) => e.direccion.razonSocial).join(', ')
          },
          cellStyle: function (params) {
            if (params.value === 'Sin emplazamientos')
              return { fontStyle: 'italic', fontWeight: 'bold' }
            return null
          },
        },
        {
          headerName: 'Auditores',
          field: 'auditores.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            return item.auditores.map((a) => a.nombreCompleto).join(', ')
          },
        },
        {
          headerName: 'Tipo Auditoria',
          field: 'tipoVisita',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item.tipoVisita || item.tipoVisita.length === 0) return 'Sin tipo de visita'
            return item.tipoVisita.map((v) => v).join(', ')
          },
          cellStyle: function (params) {
            if (params.value === 'Sin tipo de visita')
              return { fontStyle: 'italic', fontWeight: 'bold' }
            return null
          },
        },
        {
          headerName: 'Nº Pacs',
          field: 'n_pacs',
          enableRowGroup: true,
          sortable: false,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item.pacs || item.pacs.length === 0) return 'Sin PACs'
            return item.pacs.length
          },
          cellStyle: function (params) {
            if (params.value === 'Sin PACs') return { fontStyle: 'italic', fontWeight: 'bold' }
            return null
          },
        },
        {
          headerName: 'Nº de Muestras',
          field: 'n_muestras',
          enableRowGroup: true,
          sortable: false,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (!item.tomaMuestras || item.tomaMuestras.length === 0) return 'Sin muestras'
            return item.tomaMuestras.length
          },
          cellStyle: function (params) {
            if (params.value === 'Sin muestras') return { fontStyle: 'italic', fontWeight: 'bold' }
            return null
          },
        },
        {
          headerName: 'Fechas',
          field: 'desde',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            if (item.desde === item.hasta) return moment(item.desde).format('DD/MM/YYYY')
            return `Del ${moment(item.desde).format('DD/MM/YYYY')} al ${moment(item.hasta).format(
              'DD/MM/YYYY'
            )}`
          },
        },
        {
          headerName: 'Acciones',
          field: 'acciones',
          enableRowGroup: true,
          sortable: false,
          filter: false,
          resizable: true,
          cellRendererFramework: 'AuditoriaActionRenderer',
          cellRendererParams: {
            init: this.init.bind(this),
          },
          pinned: 'right',
        },
      ]

      return headers
    },

    //+-------------------------------------------------
    // allowedToOffline()
    // return items where estado == 'abierta' and checklists.length > 0
    // -----
    // Created on Wed Jan 11 2023
    //+-------------------------------------------------
    allowedToOffline() {
      return this.items.filter(
        (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
      )
    },
  },
  methods: {
    chipColor(estado) {
      // Lógica para determinar el color del chip basado en el estado
      switch (estado) {
        case 'PREVISTA':
          return 'teal lighten-2'
        case 'ABIERTA':
          return 'info'
        case 'CERRADA':
        case 'REALIZADA':
          return 'success'
        case 'CONFIRMADA':
          return 'warning'
        default:
          return '' // color predeterminado
      }
    },
    goToCreate() {
      this.$router.push('/auditorias/nueva')
    },
    async init() {
      this.ui.loading = true
      const body = {}
      if (this.expediente) body.expediente = this.expediente
      // Cliente
      if (this.cliente) body.cliente = this.cliente
      else if (this.isCliente) {
        const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
        if (clientes) body.cliente = clientes[0].uuid
      }

      // Auditorias
      const res = await this.$store.dispatch('auditorias/list', body)
      if (res) {
        this.items = res
        this.db.items = res
        /* this.items = [
          {
            id: '1',
            estado: 'ABIERTA',
            subalcance: {
              color: 'error',
              nombre: 'subalcance'
            },
            expediente: {
              nombre: 'expediente',
              codigo: 'codigo'
            },
            cliente: {
              nombre: 'cliente',
              codigo: 'codigo'
            },
            emplazamientos: 'emplazamientos',
            auditores: 'auditores',
            tipoAuditoria: 'tipoAuditoria',
            n_pacs: 0,
            n_muetsras: 0,
            fechas: '2023-12-12 - 2024-01-12'
          },
          {
            id: '2',
            estado: 'CERRADA',
            subalcance: {
              color: 'error',
              nombre: 'subalcance'
            },
            expediente: {
              nombre: 'expediente',
              codigo: 'codigo'
            },
            cliente: {
              nombre: 'cliente',
              codigo: 'codigo'
            },
            emplazamientos: 'emplazamientos',
            auditores: 'auditores',
            tipoAuditoria: 'tipoAuditoria',
            n_pacs: 0,
            n_muetsras: 0,
            fechas: '2023-12-12 - 2024-01-12'
          },
          {
            id: '3',
            estado: 'CONFIRMADA',
            subalcance: {
              color: 'error',
              nombre: 'subalcance'
            },
            expediente: {
              nombre: 'expediente',
              codigo: 'codigo'
            },
            cliente: {
              nombre: 'cliente',
              codigo: 'codigo'
            },
            emplazamientos: 'emplazamientos',
            auditores: 'auditores',
            tipoAuditoria: 'tipoAuditoria',
            n_pacs: 0,
            n_muetsras: 0,
            fechas: '2023-12-12 - 2024-01-12'
          },
        ]
        this.db.items = this.items */
      }

      //await this.formatRows()
      if (this.$nuxt.isOffline) {
        this.filterOffline(true)
        this.retryOffline()
      }
      this.ui.loading = false
    },

    async getTiposVisita() {
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        codigo: ['tipos_auditorias'],
      }
      const res = await this.$store.dispatch('estaticos/list', body)
      if (res) {
        const tipos_auditorias = {}
        for (let i = 0; i < res.length; i++) {
          const estatico = res[i]
          const alcance_uuid = estatico.alcances?.uuid
          const items = estatico.valor

          if (!tipos_auditorias[alcance_uuid]) tipos_auditorias[alcance_uuid] = {}

          for (let k = 0; k < items.length; k++) {
            const item = items[k]
            tipos_auditorias[alcance_uuid][item.valor] = item.texto
          }
        }
        this.tipos_auditorias = tipos_auditorias
      }
    },

    clickRow(data) {
      if (data) this.$router.push(`/auditorias/${data.uuid}?tab=0`)
    },

    async deleteRow(data) {
      console.log(data)
      this.deleteItemData = data
      if (data) {
        this.confirmDelete = true
        //await this.$store.dispatch('auditorias/delete', data)
        //this.init()
      }
    },

    async deleteItem() {
      if (this.deleteItemData) {
        await this.$store.dispatch('auditorias/delete', this.deleteItemData)
        this.init()
      }
    },

    //+-------------------------------------------------
    // refreshRows()
    // Reload rows and cell data
    // -----
    // Created on Mon Mar 07 2022
    //+-------------------------------------------------
    async refreshRows(init = false) {
      if (init) await this.init()
      this.formatRows()
      this.gridApi.refreshCells({ force: true })
    },

    //+-------------------------------------------------
    // formatRows()
    // Prepare data fields for grid
    // Has offline functionality
    // -----
    // Created on Thu Nov 04 2021
    // Updated on Tue Nov 09 2021
    //+-------------------------------------------------
    async formatRows() {
      for (const item of this.items) {
        item.nombreApellidos = item.nombre_cliente

        item.desde_f = this.$moment(item.desde).format('DD/MM/YYYY')
        item.hasta_f = this.$moment(item.hasta).format('DD/MM/YYYY')

        let cache = await this.$store.dispatch('offline/getCache', 'auditorias/' + item.uuid)
        let toRemove = false

        if (cache?.data && cache?.data?.data?.updated_at) {
          let diff = this.$moment(cache?.data && cache?.data?.data?.updated_at).diff(
            item.updated_at,
            'minutes'
          )

          // Delete if cache is older than 2 days
          // Disabled because there is no item.updated_at anymore 💁‍♀️
          // if (diff > 60 * 24 * 2) toRemove = true

          // Delete if item is no longer locked
          // if (!item.bloqueada && !item.bloqueada_at) toRemove = true

          // if (diff * -1 > 30) item.cached = 'conflicted'

          console.warn(
            // diff,
            // cache?.data?.data.updated_at,
            // item.updated_at,
            cache?.data?.data.uuid,
            item.uuid
          )

          item.cached = cache ? true : false
          // item.cached = cache && !toRemove ? true : false
        }

        if (!this.isOffline && toRemove) {
          console.warn(
            `Se ha eliminado el offline de ${item.uuid} porque la auditoria ya no está bloqueada`
          )
          this.$store.commit('offline/removeItem', 'auditorias/' + item.uuid)
        }
      }

      this.ui.refresh++
    },

    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },

    //+-------------------------------------------------
    // filterOffline()
    // Set items only with preloaded items
    // Used while offline to see only preloaded items
    // -----
    // Created on Wed Mar 02 2022
    //+-------------------------------------------------
    filterOffline(offline) {
      if (!offline) {
        this.items = [...this.db.items]
        this.formatRows()
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.cached
      })

      this.items = items
    },

    retryOffline() {
      if (this.ui.retry > 5) return
      if (this.items.length > 0) return

      window.setTimeout(() => {
        this.refreshRows(true)
        this.ui.retry++
      }, 1000)
    },

    //+-------------------------------------------------
    // dropCache()
    // Created on Wed Jan 11 2023
    //+-------------------------------------------------
    dropCache() {
      this.$store.commit('offline/resetCache')
      this.$store.commit('offline/clearLocalData')
      location.reload()
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        return: 'all',
      })
    },
  },

  mounted() {
    this.$store.commit('menu/setPageTitle', 'Auditorías')
    this.checkRoles()

    // this.$nuxt.$on('offline:loaded', (payload) => {
    //   if (payload.item == 'auditoria') {
    //     this.refreshRows()
    //     // this.lockAuditoria(payload)
    //   }
    // })

    // this.$nuxt.$on('offline:end', (payload) => {
    //   window.setTimeout(() => {
    //     this.refreshRows()
    //   }, 300)
    // })
  },

  async created() {
    this.$store.commit('menu/update', 'auditorias')
    await this.getTiposVisita()
    // await this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('offline:loaded')
    this.$nuxt.$off('offline:end')
  },
}
</script>
