<template>
  <div>
    <!-- CABECERA DE LA APLICACIÓN -->
    <v-app-bar id="toolbar" color="#e9eaea" app flat>
      <v-btn icon @click="drawerMenu()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn icon @click="goBack()" v-show="!$route.path.includes('dashboard')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-2">
        {{ $nuxt.$store.state.menu.pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu bottom min-width="250px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            
            <!-- <v-avatar v-if="$auth.user.avatar " class="mx-4" size="36">
              <v-img :src="$auth.user.avatar ? $auth.user.avatar : require('~/static/Profile_avatar_placeholder_large.png')" alt="Avatar" cover></v-img>
            </v-avatar> -->
            <v-avatar color="primary lighten-2" size="36">
              <span class="white--text">{{ userInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="primary lighten-2" class="mb-3">
                <span class="white--text headline">{{ userInitials }}</span>
              </v-avatar>
              <h3>{{ $auth.user.username }}</h3>
              <p class="caption">
                {{ $auth.user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn v-show="!isTecnico" depressed rounded text> Gestionar mis datos </v-btn>
              <v-divider v-show="!isTecnico" class="my-3"></v-divider>
              <v-btn color="error" depressed rounded text @click="dialogConfirmExit = true">
                Desconectar
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- MENÚ LATERAL -->
    <v-navigation-drawer
      v-model="$store.state.menu.openMenu"
      app
      floating
      color="primary"
      width="275px"
      style="height: 100vh"
      id="portal-client__main-menu">
      <v-row no-gutters justify="center" class="mb-3">
        <v-col
          class="mt-3 py-1 px-1 col-11"
          style="background: linear-gradient(#353a4c, #4f5772); border-radius: 10px">
          <v-img :src="require('~/static/logo_horizontal.png')" contain></v-img>
        </v-col>
      </v-row>

      <v-list id="menu" class="transparent" style="height: 82.5vh; overflow: auto">
        <v-list-item @click="linkTo('dashboard')">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div v-for="(block, i) in menuBlocks" :key="i">
          <v-subheader class="mt-5" style="font-weight: bold" v-show="ui.blocks[i] > 0">
            {{ block }}
          </v-subheader>

          <template v-for="(item, index) in menu">
            <v-list-item
              :key="i + index"
              v-if="item.type === 'link' && item.block == i"
              @click="linkTo(item.link)">
              <v-list-item-icon class="mr-3">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name.code ? ui.text._(item.name.code) : item.name.es_ES }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              :key="i + index + 'group'"
              v-if="item.type === 'menu' && item.block == i"
              color="accent">
              <template v-slot:activator>
                <v-list-item-icon v-if="item.icon" class="mr-3">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title @click="linkTo(item.link)">
                  {{ item.name.es_ES }}
                </v-list-item-title>
              </template>

              <template v-for="(submenu, indexSubmenu) in item.items">
                <v-list-item
                  link
                  style="padding-left: 55px"
                  @click="linkTo(submenu.link)"
                  :key="indexSubmenu">
                  <v-list-item-title v-text="submenu.name.es_ES"></v-list-item-title>
                  <v-list-item-icon v-if="submenu.icon">
                    <v-icon v-text="submenu.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!-- Confirmación Logout -->
    <Confirmation
      v-model="dialogConfirmExit"
      text="¿Está seguro que desea salir de la aplicación?"
      colorButton="error"
      textButton="Salir"
      @confirm="logout()" />

    <!-- Formatos -->
    <Formatos v-model="showFormatos" @close="showFormatos = false" />

    <!-- LISTAS DE VALIDACIÓN -->
    <ListasValidacion v-model="showListaValidacion" />

    <!-- NOTIFICACIONES -->
    <!-- <Notificaciones v-model="showNotificaciones" @close="showNotificaciones = false"/> -->

    <!-- Gestor Documental -->
    <Share />
    <Uploader />

    <Signatures />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\admin\Navigation.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Fri Feb 10 2023
 **/

import text from '~/models/labels'
import menuClients from '~/models/menuClients'
import menuTecnicos from '~/models/menuTecnicos'
import menuAdmins from '~/models/menuAdmins'
// import menuEnlacesRapidos from '~/models/menu_enlaces_rapidos'

import Formatos from '~/components/Formatos'
import ListasValidacion from '~/components/ListasValidacion'
import Notificaciones from '~/components/Notificaciones'
import Confirmation from '~/components/Confirmation'
import Signatures from '~/components/signatures/Sign'
import Uploader from '~/components/documentsUploader'
import Share from '~/components/Share'
// import ROLE from '~/models/role'
import { mapState } from 'vuex'

export default {
  name: 'Menu',
  components: {
    Formatos,
    ListasValidacion,
    Notificaciones,
    Confirmation,
    Share,
    Uploader,
    Signatures,
  },
  data: () => ({
    menuBlocks: {
      gestion: 'Gestión',
    },

    ui: {
      text: text,
      blocks: {},
    },

    toolbar: null,
    dialogConfirmExit: false,
    // menu: menuItems,
    // menu_enlaces_rapidos: menuEnlacesRapidos,
    menuOpcionesGenerales: false,
    menuGeneral: false,
    showFormatos: false,
    showListaValidacion: false,
    showNotificaciones: false,
  }),
  computed: {
    ...mapState({
      menuEmpresa: (state) => state.menu.menuEmpresa,
      openMenu: (state) => state.menu.openMenu,
      menuTheme: (state) => state.menu.menuTheme,
      menuSeleccionado: (state) => state.menu.menuSeleccionado,
      listaValidacion: (state) => state.listas_validacion.lista,
      verListaValidacion: (state) => state.listas_validacion.verListaValidacion,
      formatos: (state) => state.formatos.list,
    }),

    menu() {
      let items = []
      let count = {
        gestion: 0,
        administracion: 0,
      }
      let menu = this.isAdminGroup ? menuAdmins : this.isTecnico ? menuTecnicos : menuClients
      // Menu now is the same for tecnicos as well
      // https://kaamit.atlassian.net/browse/CERTIAPP-1340
      // menu = menuClients

      for (const item of menu) {
        if (item.link == 'portal/cooperativas' && this.$auth.user.empresa.codigo !== 'coplaca') {
          continue
        }

        // console.warn(item, item.onlyFor)
        if (item.notFor && item.notFor.includes(this.cod_empresa)) continue
        if (item.onlyFor && !item.onlyFor.includes(this.cod_empresa)) continue
        // console.warn(item)

        count[item.block]++
        items.push(item)
      }

      this.ui.blocks = count
      return items
    },

    roles() {
      return this.$auth.user.roles
    },

    verFormatos() {
      return this.$store.getters['formatos/verFormatos']
    },

    documentos() {
      return this.$store.getters['gestor_documental/documentos']
    },

    notificacionesPendientes() {
      return this.$store.getters['notificaciones/notificacionesPendientes']
    },

    cod_empresa() {
      return this.$auth.user.empresa.codigo
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    isAdmin() {
      return this.$store.getters.isAdmin
    },

    isAdminGroup() {
      return this.$store.getters.isAdminGroup
    },

    isTecnico() {
      return this.$auth.user.tipo == 'tecnico'
      return this.$auth.user.roles.indexOf('ROLE_TECNICO') > -1
    },

    // filteredMenu() {
    //   return !this.isCliente
    //     ? this.menu.filter((element) => element.role !== ROLE.ROLE_CLIENT)
    //     : this.menu
    // },
    // enlacesRapidos() {
    //   return this.menu_enlaces_rapidos
    // },
    // filteredEnlacesRapidos() {
    //   return !this.isCliente
    //     ? this.menu_enlaces_rapidos.filter((element) => element.role !== ROLE.ROLE_CLIENT)
    //     : this.menu_enlaces_rapidos
    // },
    // enlacesRapidos() {
    //   if (this.filteredEnlacesRapidos && this.roles) {
    //     return this.filteredEnlacesRapidos.filter((item) => {
    //       return this.roles.indexOf(item.role) !== -1
    //     })
    //   }
    // },

    userInitials() {
      let name = this.$auth.user.username
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

      let initials = [...name.matchAll(rgx)] || []
      initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()

      return initials
    },
  },
  beforeMount() {
    this.init()
  },
  mounted() {
    let vm = this
    this.toolbar = document.getElementById('toolbar')
    // Control del aviso de notificaciones
    // setInterval(() => {
    //   if (vm.notificaciones_pendientes) $(".target").effect("shake", {times: 3}, 1000)
    //   else $(".target").effect("", {times: 0}, 0)
    // }, 1500)
  },
  methods: {
    async init() {
      return
      // let cliente_uuid = ''
      // if (this.isCliente) {
      //   let user = this.$auth.user
      //   cliente_uuid = user.contacto.cliente.uuid
      // }

      // // Menu
      // this.$store.dispatch('menu/get', cliente_uuid)
    },

    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },
    goBack() {
      this.$router.go(-1)
    },

    linkTo(link) {
      if (link) {
        this.$store.commit('menu/update', link)
        this.$router.push('/' + link)
      }
    },

    showSubmenuEmpresa(menu, submenu) {
      // const modulo = menu.modulo
      // const submodulo = submenu.submodulo
      // const role = submenu.role
      // return this.menuEmpresa.some(element => element.modulo === modulo && element.submodulo === submodulo)
      const permisos = this.$auth.user.permisos
      if (permisos) {
        const roles = this.$auth.user.roles
        const canList = permisos.some((e) => {
          return (
            // e.can_-list &&
            e.can_config.canList &&
            e.modulo === menu.modulo &&
            e.submodulo === submenu.submodulo &&
            roles.includes(e.role.name)
          )
        })
        return canList
      }
      return false
    },
    openCloseMenu() {
      this.$store.commit('menu/drawerMenu', this.openMenu)
    },
    drawerMenu() {
      this.$store.commit('menu/drawerMenu', !this.openMenu)
    },
    controlToolbarSize() {
      if (!this.openMenu) {
        this.toolbar.classList.add('toolbar-no-menu')
        this.toolbar.classList.remove('toolbar-width-menu')
      } else {
        this.toolbar.classList.remove('toolbar-no-menu')
        this.toolbar.classList.add('toolbar-width-menu')
      }
    },

    openListaValidacion() {
      if (this.$route.name.indexOf('uuid') !== -1) this.showListaValidacion = true
    },
  },
}
</script>

<style scoped>
#portal-client__main-menu {
  background-color: transparent !important;
  background-color: #e9eaea !important;
  /* border-right: 1px solid #dcdcdc !important; */
}

.v-navigation-drawer {
  z-index: 7 !important;
}

.toolbar-div {
  height: 64px;
}

.toolbar-width-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 275px;
  position: fixed;
}

.toolbar-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  position: fixed;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
#menu {
  -ms-overflow-style: none;
}
</style>
