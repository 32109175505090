import ROLE from './role'

export default [
  {
    name: {
      es_ES: 'Empresas',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/empresas',
    icon: 'mdi-account-edit',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Marcas',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/marcas',
    icon: 'mdi-cards',
    role: ROLE.ROLE_AUDITOR,
  },


  {
    name: {
      es_ES: 'Documentos',
      en_US: 'Documents',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/documentos',
    icon: 'mdi-cloud-outline',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Certificados',
      en_US: 'Certifies',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/certificados',
    icon: 'mdi-cards',
    role: ROLE.ROLE_AUDITOR,
  },
  {
    name: {
      es_ES: 'Noticias',
      en_US: 'News',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/news',
    icon: 'mdi-newspaper',
    role: ROLE.ROLE_AUDITOR,
  },
  {
    name: {
      es_ES: 'Membresías',
      en_US: 'Memberships',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'admin/membresias',
    icon: 'mdi-account-group',
    role: ROLE.ROLE_ADMIN,
  }
]
