<template>
  <div class="d-flex">
    <v-container>
      <v-autocomplete
        ref="select"
        label="Localidad"
        v-model="selected"
        :items="items"
        :loading="ui.loading"
        :search-input.sync="search"
        :placeholder="ui.loading ? 'Cargando...' : 'Escriba para buscar la localidad deseada'"
        item-text="nombre"
        item-value="uuid"
        :required="required"
        :rules="rules"
        :disabled="disabled"
        :readonly="readonly"
        :dense="dense"
        :outlined="outlined"
        :filled="filled"
        :hide-details="hideDetails"
        :return-object="returnObject"
        no-data-text="Listado vacío, seleccione la localidad."
        :error="error"
        :error-messages="errorMessages"
        :hint="hint"
        persistent-hint
        @focus="loadNextPage()"
        @change="emitChange()">
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title v-html="data.item?.nombre || ''"></v-list-item-title>
              <span style="font-size: 14px">{{ formatLocation(data.item) }}</span>
            </v-list-item-content>
          </template>
        </template>

        <template v-slot:append-outer class="ma-1" v-if="this.allowCreate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                text
                small
                @click="$refs.crud.newItem()"
                style="transform: translateY(-2px)">
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
            </template>
            Crear una nueva localidad
          </v-tooltip>
        </template>
      </v-autocomplete>
    </v-container>
    <crud-dialog ref="crud" @stored="eventStored"> </crud-dialog>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/forms/SelectLocalidades.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 9th June 2021
 * Last Modified: Fri Jul 21 2023
 **/

import notifications from '~/util/notificationHelper'
import crudDialog from '~/pages/localidades/widgets/crudDialog'

export default {
  name: 'SelectLocalidad',
  components: {
    crudDialog,
  },

  props: {
    value: [String, Object],
    label: { type: String, default: 'Locaidad' },
    readonly: { type: Boolean, default: false },
    empresa: { type: Object, default: null },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    hideDetails: { type: String|Boolean, default: false },
    returnObject: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    codigo: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
    empresa: { type: Object, default: null },
    
  },

  data() {
    return {
      selected: null,
      search: null,
      selectedObject: null,
      db: {
        localidades: [],
      },
     // hint:{},

      ui: {
        loading: true,
        currentPage: 1,
        perPage: 30,
        totalItems: 0,
      },
    }
  },

  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },

    items() {
      return this.db.localidades
    },


    hint() {
      let hint = ''
      if(this.value && typeof this.value == 'object') {
        hint = this.value.municipioNombre ? this.value.municipioNombre : ''
  
        hint += this.value.provinciaNombre ? ' (' + this.value.provinciaNombre  +')': ''

         hint += this.value.comunidadNombre ? ' - '+this.value.comunidadNombre : ''
        
        hint += this.value.paisNombre ? ' / ' + this.value.paisNombre : ''
      }else{
        if (this.value && typeof this.value == 'string') {
          const localidad = this.db.localidades.find(item => item.uuid === this.value)
          if (!localidad) return hint
          console.log('localidad', localidad)
          hint = localidad.municipioNombre ? localidad.municipioNombre : ''
          hint += localidad.provinciaNombre ? ' (' + localidad.provinciaNombre  +')': ''
          hint += localidad.comunidadNombre ? ' - '+localidad.comunidadNombre : ''
          hint += localidad.paisNombre ? ' / ' + localidad.paisNombre : ''
        }
      }
      return hint
    },
  },

  watch: {
    value(val) {
      if (!val && this.$refs.select) {
        this.$refs.select.resetValidation()
      }
      //if val is a object, we need to set the add the object to db.localidades
      if (val && typeof val == 'object') {
        console.log('val', val)
        this.db.localidades.push(val)
        this.selected = val.uuid
      } else {
        this.selected = val
      }
    },

    codigo(val) {
      console.log('codigo', val)
      if (this.codigo) this.setValueByCodigo(this.codigo)
    },
    search(val) {
      this.ui.loading = true
      if (val && val.length >= 2) {
        this.getLocalidades(val)
      }
      this.ui.loading = false
    },
  },

  methods: {
    //+-------------------------------------------------
    // setValueByCodigo()
    // Useful method to be called by parent component
    // update value in this
    // -----
    // Created on Mon Mar 21 2022
    //+-------------------------------------------------
    setValueByCodigo(codigo) {
      const selected = this.items.find((e) => e.codigo == codigo)
      this.selected = { ...selected }
      this.$emit('input', selected)
    },

    emitChange() {
      console.log('emitChange')
      if (this.selected == null) this.getLocalidades()
      else {
        this.selectedObject = this.getLocalidadObject(this.selected)
        setTimeout(() => {
          this.$emit('input', this.selected)
          this.$emit('change', this.selected, this.selectedObject)

          $nuxt.$emit('localidades:selected', {
            uuid: this.selected,
            localidad: this.selectedObject,
          })
        }, 500)
      }
    },

    eventStored(payload) {
      this.db.localidades.push(payload.item)
      notifications.show({ text: 'La localidad se ha guardado correctamente' })
      this.selected = payload.item.uuid

      this.emitChange()
    },

    async getLocalidades(search = null) {
      this.ui.loading = true
      let xhr = null
      console.log('getLocalidades', search)
      let body = {
        page: this.ui.currentPage,
        perPage: this.ui.perPage,
      }
      if (this.empresa) {
        body.empresa = this.empresa.uuid
      }

      if (search) {
        body.nombre = search
      }

      xhr = await this.$store.dispatch('localidades/customlist', body).then((res) => {
        return res
      })

      if (xhr && this.ui.currentPage == 1) {
        this.db.localidades = xhr['hydra:member']
        /* this.db.localidades.forEach((direccion) => {
          this.hint = {
            municipio: direccion.localidad ? direccion.municipioNombre : '',
            provincia: direccion.localidad ? direccion.provinciaNombre : '',
            comunidad: direccion.localidad ? direccion.comunidadNombre : '',
            pais: direccion.localidad ? direccion.paisNombre : '',
          }
        }) */
        this.ui.totalItems = xhr['hydra:totalItems']
      }
      if (xhr && this.ui.currentPage > 1) {
        this.db.localidades = this.db.localidades.concat(xhr['hydra:member'])
        this.ui.totalItems = xhr['hydra:totalItems']
      }

      if (this.value && this.value.uuid != '') {
        if (typeof this.value == 'object') {
          this.db.localidades.push(this.value)
          this.selected = this.value.uuid
        }
      }

      this.ui.loading = false
    },

    formatLocation(item) {
      if (!item) return '';
      console.log('formatLocation', item)
      const parts = [
        item.nombre,
        item?.municipio?.nombre,
        item?.municipio?.provincia?.nombre
      ].filter(Boolean); // Elimina valores null/undefined
      
      return parts.join(', ');
    },

    async getLocalidadObject(paramUuid) {
      return await this.$store.dispatch('localidades/listByUuid', {uuid: paramUuid}).then((res) => {
        return res.data
      })
    },

    loadNextPage() {
      /* setInterval(() => {
        if (this.ui.loading) return
        if (this.ui.currentPage * this.ui.perPage >= this.ui.totalItems) return
        this.ui.currentPage++
        this.getLocalidades()
      }, 300) */
    },

    async init() {
      this.selected = this.value
      await this.getLocalidades()
      if (this.codigo) this.setValueByCodigo(this.codigo)

      this.ui.loading = false
    },
  },

  created() {
    this.init()
  },
}
</script>
