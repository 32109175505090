export const state = () => ({
  items: [],
})

export const mutations = {
  setMemberships(state, memberships) {
    state.items = memberships
  },
  addMembership(state, membership) {
    state.items.push(membership)
  },
  updateMembership(state, updatedMembership) {
    const index = state.items.findIndex((item) => item.uuid === updatedMembership.uuid)
    if (index !== -1) {
      state.items.splice(index, 1, updatedMembership)
    }
  },
  deleteMembership(state, uuid) {
    state.items = state.items.filter((item) => item.uuid !== uuid)
  },
}

export const actions = {
  async fetchMemberships({ commit }) {
    // Simulamos una llamada al API
    const memberships = [
      {
        uuid: 'platinum123',
        title: 'PLATINUM',
        description:
          '💎 Organismos acreditados y personas clave que participaron en la fundación de la organización.',
        image: '/images/platinum_card.jpg',
        icon: 'mdi-diamond-stone',
        iconColor: 'blue lighten-2',
        popular: false,
        price: 1000, // Precio en la moneda local
        availability: 'Disponible', // Estado de la membresía (Disponible, No Disponible, etc.)
        benefits: [
          'Acceso a eventos exclusivos',
          'Asistencia prioritaria en soporte',
          'Descuentos del 50% en cursos y certificaciones',
          'Consultas con expertos',
          'Oportunidad de contribuir a decisiones estratégicas',
        ],
        terms: [
          'Válido por 1 año desde la fecha de compra.',
          'No reembolsable.',
          'Renovación automática a menos que se cancele.',
        ],
        priorityAccess: true, // Acceso prioritario
        featured: false, // Para destacar la membresía
      },
      {
        uuid: 'gold456',
        title: 'GOLD',
        description:
          '🏆 Membresía para organismos acreditados entre 6 y 24 meses desde la fundación.',
        image: '/images/gold_card.jpg',
        icon: 'mdi-trophy',
        iconColor: 'amber darken-2',
        popular: true,
        price: 700,
        availability: 'Disponible',
        benefits: [
          'Acceso a eventos regulares',
          'Asistencia en soporte',
          'Descuentos del 30% en cursos y certificaciones',
          'Oportunidad de participar en comités',
        ],
        terms: [
          'Válido por 1 año desde la fecha de compra.',
          'Renovable bajo solicitud.',
          'No acumulable con otras promociones.',
        ],
        priorityAccess: false,
        featured: true,
      },
      {
        uuid: 'silver789',
        title: 'SILVER',
        description:
          '🌟 Membresía para aquellos que se unieron después de 2 años desde la fundación.',
        image: '/images/silver_card.jpg',
        icon: 'mdi-star-circle',
        iconColor: 'grey lighten-1',
        price: 400,
        availability: 'Limitada',
        benefits: [
          'Acceso básico a eventos',
          'Descuentos del 15% en cursos y certificaciones',
          'Acceso a la comunidad y foros',
        ],
        terms: [
          'Válido por 6 meses desde la fecha de compra.',
          'No reembolsable.',
          'Renovable anualmente.',
        ],
        priorityAccess: false,
        featured: false,
      },
    ]

    commit('setMemberships', memberships)
  },
  async createMembership({ commit }, membership) {
    // Simulación de creación en API
    commit('addMembership', membership)
  },
  async updateMembership({ commit }, membership) {
    // Simulación de actualización en API
    commit('updateMembership', membership)
  },
  async deleteMembership({ commit }, uuid) {
    // Simulación de eliminación en API
    commit('deleteMembership', uuid)
  },
}
